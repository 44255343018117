import React from 'react';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import styled from 'styled-components';
import { Heading, Subtitle } from '@/styles/global-styled-components';
import { Button } from '@/components/Base/Button';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  a {
    color: #0095eb;
  }

  ul {
    margin: 0 0 15px 30px;
    list-style: disc outside;
  }

  ol,
  li {
    margin-bottom: 10;
  }
  h3 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const ViewportHeight = styled.div`
  @media ${(props) => `${props.theme.screens.landscape.raw}`} {
    // min-height: 700px;
    height: 93vh;
    max-height: -webkit-fill-available; // for safari
  }
`;

const About = () => {
  const aboutItems = [
    {
      title: 'Market education',
      description: 'Increasing awareness of the Volmex ecosystem',
    },
    {
      title: 'Partnerships',
      description: 'Working with various partners to support the ecosystem',
    },
    {
      title: 'Community',
      description: 'Growing the ecosystem through organizing events',
    },
    {
      title: 'Technology',
      description:
        'Supporting smart contract deployments, web application deployments, and more',
    },
  ];
  return (
    <Layout>
      <SEO />
      <Container>
        <ViewportHeight className="mb-10 page-padding flex flex-col align-middle justify-between relative items-center text-white">
          <div>
            <div className="landscape:w-full">
              <div className="text-center text-5xl mt-32 mb-20">
                About Volmex Foundation
              </div>
              <p>
                Volmex Foundation is a nonprofit entity that was created to
                support the Volmex ecosystem. Volmex Foundation's mission is to
                support open, free, and fair financial markets by making crypto
                volatility indices and volatility products more globally
                accessible.
              </p>
              <div className="mt-12 mb-12">
                <p className="text-2xl mb-12">
                  Our mandate is specifically focused on several key areas:
                </p>
                <div>
                  {aboutItems.map((item) => (
                    <div className="mb-6">
                      <h3 className="text-xl">{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ViewportHeight>
      </Container>
    </Layout>
  );
};

export default About;
